var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"downloads"},[_c('div',{staticClass:"top-section"},[_c('div',{staticClass:"text"},[_c('h2',{domProps:{"innerHTML":_vm._s(_vm.toLocal('downloads.more_info'))}}),_c('span',[_vm._v(_vm._s(_vm.toLocal("downloads.read")))])]),_c('div',{staticClass:"red-bar"},[_c('span',[_vm._v(_vm._s(_vm.toLocal("downloads.nfpa"))+":")]),_c('div',{staticClass:"video-container"},_vm._l((_vm.safetyTips),function(video,index){return _c('div',{key:index,staticClass:"video"},[_c('iframe',{attrs:{"src":video.embed_url,"title":"YouTube video player","frameborder":"0","allow":"accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture","allowfullscreen":""}}),_c('span',[_vm._v(_vm._s(video.url))])])}),0)])]),_c('div',{staticClass:"bottom-section"},[_c('div',{staticClass:"leaflet-container"},[_c('h3',[_vm._v(_vm._s(_vm.toLocal("downloads.check_leaf")))]),_c('span',[_vm._v(_vm._s(_vm.toLocal("downloads.download_leaf")))]),_c('div',{staticClass:"leaflets"},_vm._l((_vm.leaflets),function(leaflet,index){return _c('div',{key:index,staticClass:"leaflet",style:({
            backgroundImage:
              'url(' + require(`@/assets/${leaflet.img}`) + ')',
          }),on:{"click":function($event){return _vm.downloadLeaflet(leaflet)}}},[_c('div',{staticClass:"bottom-content"},[_c('div',{staticClass:"leaflet-text"},[_vm._v(" "+_vm._s(_vm.toLocal(leaflet.text))+" ")]),_c('div',{staticClass:"download-btn"},[_c('svg',{attrs:{"width":"37","height":"37","viewBox":"0 0 37 37","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"d":"M13.875 0V13.875H4.625L18.5 27.75L32.375 13.875H23.125V0H13.875ZM0 32.375V37H37V32.375H0Z","fill":"white"}})])])])])}),0)]),_c('div',{staticClass:"why-videos"},[_c('span',{staticClass:"why-videos-title"},[_vm._v(_vm._s(_vm.toLocal("downloads.why_installing")))]),_c('span',{staticClass:"why-videos-subTitle"},[_vm._v(_vm._s(_vm.toLocal("downloads.videos_ireland")))]),_c('div',{staticClass:"main-video"},[_c('swiper',{ref:"swiper",attrs:{"slides-per-view":1,"space-between":0,"allowTouchMove":false,"direction":"vertical"},on:{"swiper":_vm.onSwiper}},_vm._l((_vm.videoStack),function(video,index){return _c('swiper-slide',{key:`${video.link}-${index}`},[_c('video-player',{ref:`mainMessageVideo-${index}`,refInFor:true,attrs:{"options":{
                autoplay: false,
                controls: true,
                sources: [
                  {
                    src: require(`@/assets/${video.link}`),
                    type: 'video/mp4',
                  },
                ],
              }}})],1)}),1)],1),_c('div',{staticClass:"other-videos"},_vm._l((_vm.windowWidth < 768 ? _vm.videos : _vm.footerVideos),function(video,index){return _c('div',{key:index,staticClass:"video",class:{ loading: _vm.isLoadingNewVideo }},[_c('transition',{attrs:{"name":"fade","mode":"out-in"}},[(_vm.windowWidth < 768)?_c('video',{ref:`video-${index}`,refInFor:true,attrs:{"controls":""}},[_c('source',{attrs:{"src":require(`@/assets/${video.link}`),"type":"video/mp4"}}),_vm._v(" Your browser does not support the video tag. ")]):_c('img',{key:video.id,attrs:{"src":require(`@/assets/${video.img}`),"alt":""},on:{"click":function($event){return _vm.setMainMessageVideo(video, index)}}})]),_c('transition',{attrs:{"name":"fade","mode":"out-in"}},[(_vm.windowWidth >= 768)?_c('div',{key:video.id,staticClass:"play-button",on:{"click":function($event){return _vm.setMainMessageVideo(video, index)}}},[_c('svg',{attrs:{"width":"17","height":"20","viewBox":"0 0 17 20","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"d":"M0.643555 2.84994C0.643555 1.27614 2.37722 0.31895 3.70907 1.1574L15.1553 8.36327C16.4013 9.14766 16.4013 10.9639 15.1553 11.7483L3.70907 18.9542C2.37722 19.7927 0.643555 18.8355 0.643555 17.2617V2.84994Z","fill":"#1E1826"}})])]):_vm._e()])],1)}),0)]),_c('div',{staticClass:"testing-videos"},[_c('h3',[_vm._v(_vm._s(_vm.toLocal("downloads.how_to_test")))]),_c('span',{staticClass:"testing-videos-subtitle"},[_vm._v(_vm._s(_vm.toLocal("downloads.videos_usa")))]),(_vm.windowWidth >= 768)?_c('div',{staticClass:"video-container"},[_c('div',{staticClass:"col"},[_c('div',{ref:"mainTestingVideo",staticClass:"main-video"},[_c('video-player',{key:_vm.activeTestingVideo.link,ref:"testingMainVideo",attrs:{"options":{
                autoplay: false,
                controls: true,
                sources: [
                  {
                    src: require(`@/assets/${_vm.activeTestingVideo.link}`),
                    type: 'video/mp4',
                  },
                ],
              }}})],1)]),_c('div',{staticClass:"col"},[_c('div',{staticClass:"videos"},_vm._l((_vm.testing_videos.filter(
                (el) => !el.active
              )),function(video,index){return _c('div',{key:index,staticClass:"video"},[_c('transition',{attrs:{"name":"fade","mode":"out-in"}},[_c('img',{key:video.id,ref:`testing-video-primary-${index}`,refInFor:true,attrs:{"src":require(`@/assets/${video.img}`),"alt":""},on:{"click":function($event){return _vm.setMainTestingVideo(video)}}})]),_c('transition',{attrs:{"name":"fade","mode":"out-in"}},[_c('div',{key:video.id,staticClass:"play-button",on:{"click":function($event){return _vm.setMainTestingVideo(video)}}},[_c('svg',{attrs:{"width":"17","height":"20","viewBox":"0 0 17 20","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"d":"M0.643555 2.84994C0.643555 1.27614 2.37722 0.31895 3.70907 1.1574L15.1553 8.36327C16.4013 9.14766 16.4013 10.9639 15.1553 11.7483L3.70907 18.9542C2.37722 19.7927 0.643555 18.8355 0.643555 17.2617V2.84994Z","fill":"#1E1826"}})])])])],1)}),0)])]):_c('div',{staticClass:"video-container-extra"},_vm._l((_vm.testing_videos),function(video,index){return _c('div',{key:index,staticClass:"row"},[_c('video-player',{ref:"testingMainVideo",refInFor:true,attrs:{"options":{
              autoplay: false,
              controls: true,
              sources: [
                {
                  src: require(`@/assets/${video.link}`),
                  type: 'video/mp4',
                },
              ],
            }}})],1)}),0)]),_c('div',{staticClass:"red-circle"}),_c('div',{staticClass:"extra-container"},[_c('div',{staticClass:"half"},[_c('span',{staticClass:"title"},[_vm._v(_vm._s(_vm.toLocal("downloads.raising_awareness")))]),_c('span',{staticClass:"subtitle"},[_vm._v(_vm._s(_vm.toLocal("downloads.video_french")))]),_c('div',{staticClass:"video-container"},[_c('div',{staticClass:"video"},[_c('iframe',{attrs:{"src":`https://www.youtube.com/embed/9B49KCXF2BY`,"title":"YouTube video player","frameborder":"0","allow":"accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture","allowfullscreen":""}}),_c('span',[_vm._v("https://youtu.be/9B49KCXF2BY")])])])]),_c('div',{staticClass:"half"},[_c('span',{staticClass:"title"},[_vm._v(" "+_vm._s(_vm.toLocal("practices.how_to_install")))]),_c('span',{staticClass:"subtitle"},[_vm._v(_vm._s(_vm.toLocal("downloads.video_home_depot")))]),_c('div',{staticClass:"video-container"},[_c('div',{staticClass:"video"},[_c('iframe',{attrs:{"src":`https://www.youtube.com/embed/Y8duhoCdDz4`,"title":"YouTube video player","frameborder":"0","allow":"accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture","allowfullscreen":""}}),_c('span',[_vm._v("https://youtu.be/Y8duhoCdDz4")])])])])])]),_c('div',{staticClass:"facts-share"},[_c('span',{staticClass:"share-title"},[_vm._v(_vm._s(_vm.toLocal("facts.share_story")))]),_c('button',{on:{"click":_vm.mailto}},[_vm._v("info@eurofsa.org")])])])
}
var staticRenderFns = []

export { render, staticRenderFns }