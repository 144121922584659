<template>
  <path
    class="map-object"
    :class="
      ({ active: this.activeSelectedCountry == this.countryInfo.name },
      {
        grayed:
          this.activeSelectedCountry !== this.countryInfo.name &&
          this.activeSelectedCountry !== '',
      })
    "
    @click="setSelectedCountry(countryInfo.name)"
    :d="this.countryInfo.svg"
    :fill="this.countryInfo.fill"
    stroke="#1E1826"
    stroke-miterlimit="10"
    stroke-linecap="round"
    stroke-linejoin="round"
  />
</template>

<script>
export default {
  name: "CountryComponent",
  props: ["country", "setActiveCountry", "activeSelectedCountry"],
  data() {
    return {
      countryInfo: this.country,
      setCountry: this.setActiveCountry,
      activeCountry: this.activeSelectedCountry,
    };
  },
  methods: {
    setSelectedCountry(name) {
      this.setCountry(name);
    },
  },
};
</script>

<style scoped lang="scss">
.map-object:hover {
  cursor: pointer;
  fill: #483a5b;
}
.map-object {
  transition: 0.2s;
  &.grayed {
    opacity: 0.2;
  }
}
</style>
