<template>
  <div class="footer-container">
    <div class="footer-image-container">
      <div class="image-with-under-text">
        <img src="@/assets/logo.svg" alt="logo" />
        <span>{{ toLocal("home.main_date") }} {{ new Date().getFullYear() }}</span>
      </div>
    </div>
    <div class="footer-main-container">
      <div class="footer-column-wrapper">
        <span class="join-us-header">{{ toLocal("home.join_project") }}</span>
        <span class="text">EUROFSA</span>
        <span class="text email-link"
          >E-Mail: <a href="mailto:info@eurofsa.org">info@eurofsa.org</a></span
        >
        <span class="bottom-rights" v-html="toLocal('home.reserved')"></span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "FooterComponent",
  mounted(){
    this.$nextTick(() => {
      this.$el.querySelector(".year").innerHTML = new Date().getFullYear();
    })
  },
  data() {
    return {};
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/scss/variables";
.semiBold {
  font-family: $semiBoldFont;
}
.footer-container {
  display: flex;
  background-color: #483a5b;
  color: white;
  height: 300px;
  align-items: center;
  padding: 0 160px;
  @media (max-width: 1280px) {
    padding: 0 80px;
  }
  .footer-image-container {
    position: absolute;
    font-family: $mainFont;
    .image-with-under-text {
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
      justify-content: center;
      align-items: flex-start;
      text-align: start;
      img {
        width: 200px;
        height: 100px;
      }
      span {
        width: 200px;
        margin-top: 40px;
        font-size: 24px;
        line-height: 32px;
      }
    }
  }
  .footer-main-container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    .footer-column-wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      font-family: $mainFont;
      .join-us-header {
        font-size: 25px;
        margin-bottom: 55px;
      }
      .text {
        margin: 3px 0;
        font-size: 20px;
        line-height: 24px;
      }
      .bottom-rights {
        margin-top: 55px;
      }
    }
  }
  @media (max-width: 768px) {
    padding: 0 20px;
    flex-direction: column;
    height: 100%;
    padding: 20px;
    .footer-image-container {
      position: relative;
      width: 100%;
      margin-bottom: 2rem;
      .image-with-under-text {
        flex-direction: column;
        align-items: center;
        position: relative;
        max-width: 100%;

        img {
          max-height: 75px;
          max-width: 160px;
        }
        span {
          margin: 0;
          font-size: 20px;
          line-height: 32px;
          text-align: center;
          width: 160px;
        }
      }
    }
    .footer-main-container {
      .footer-column-wrapper {
        text-align: center;
        .join-us-header {
          margin-bottom: 1rem;
        }
      }
    }
    .bottom-rights {
      font-size: 14px;
      line-height: 21px;
    }
  }
}
.email-link {
  a {
    color: white;
    &:hover {
      font-family: $semiBoldFont;
    }
  }
}
</style>
