<template>
  
  <div class="privacy-container" >
    <h2>Privacy policy</h2>
  </div>
</template>

<script>
export default {
    data(){
        return{

        }
    }
}
</script>

<style lang="scss" scoped>
.privacy-container{
    min-height: 100vh;
    color:#1e1826;
    padding: 200px calc(min(160px, 10vw)) 0;
}
</style>